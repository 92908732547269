#navbar{
    width:100%;
    height:80%;
    display:flex;
    justify-content: space-around;
    color:black;
    background-color:white !important;
    overflow: hidden;
}
.link{
    text-decoration: none;
    color:black;
    font-size: larger;
    margin-left:-2%;
    font-family:"Quicksand Bold";
}
.link:hover{
    color:purple;
}

.linkContainer{
    margin-top:4%;    
}

#logoContainer{
    margin-top:-1.5%; 
    margin-left:-4%;   
}
#Logo{
    width: 60%;
    height:100%;
}

@media only screen and (max-width: 800px){
    #navbar{
        display:block;
    }
    #logoContainer{
        width:50%;
        height:50%;
        margin-left:28%;
        margin-bottom: -4%;
    }
    .linkContainer{
        margin-left:25%;
        padding: 1%;
        margin-bottom:4%;
    }
}