#math{
    background-color:black;
    color:white;
    height:10vh;
    display:flex;
    align-items: center;
}

.mathlink{
    text-decoration: none; 
    font-family:"Quicksand Bold";
    font-size:130%;
    color:black;
}
.optionContainer{
    display:flex;
    justify-content: center;
    margin:7%;
    padding:5%;
}

.optionContainer:hover, .mathlink:hover{
    background-color:#03AC13;
    color:white;
}

@media only screen and (max-width: 800px){
    .optionContainer{
        display:block;
        padding:9%;
    }

    .optionContainer:hover, .mathlink:hover{
        background-color:#03AC13;
        color:white;
    }   
}