
.IconContainer{
    display:flex;
    justify-content:center;
    margin-left:25%;
    margin-top:4%;
    font-family:"Quicksand Bold";
    margin-bottom:10%;
}
.whatsapp{
    width:20%;
    height:80%;
    margin-left:4%;
}

.EmailContainer{
    display:flex;
    justify-content:center;
    font-family:"Quicksand Bold";
    padding-bottom:10%;
}

#contacto{
    /*background-color: #311432;*/
    background-color:black;
    color:white;
}
#mainTitle{
    margin-top:5%;
}
.copyright{
    padding-top:15%;
}