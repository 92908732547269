@font-face{
    font-family:"Quicksand Light";
    src:url("../../Fonts/quicksand/Quicksand_Light.otf")
}
@font-face {
    font-family: "Quicksand Bold";
    src: url("../../Fonts/quicksand/Quicksand_Bold.otf");
}


.TextContainer{
    background-color:black;
    color:white;
    width:100vw!important;
    height:150%; 
    overflow-x: hidden !important;
}

.imageContainer, .image{
    width:60%;
    height:60%;
    margin-left:20%;
    margin-top:5%;
    margin-bottom:3%;
}

.text, .priceText{
    width:90%;
    height:100%;
    margin:5%;
    font-family:"Quicksand Light";
    font-size:125%;
    text-align: justify;
    line-height: 133%;
}
b{
    color:goldenrod;
}
.priceText{
    padding-bottom:5%;
}

.title{
    display:flex;
    justify-content: center;
    font-family:"Quicksand Bold";
    font-size:170%;
}

.subtitle{
    display:flex;
    justify-content: center;
    font-family:"Quicksand Bold";
    font-size:140%;
}

@media only screen and (max-width: 800px){
 .imageContainer, .image{
    width:80%;
    height:80%;
    margin-left:10%;
 }

 .title{
    margin:1%;
    font-size:130%;
 }

 .subtitle{
    font-size:110%;
 }

    .text{
        margin:5%;
        font-size:115%;
    }
    .priceText{
        margin:5%;
        font-size:115%;
        padding-bottom:5%;
    }
}

@media only screen and (max-width: 800px){
    .text{
        margin:10%;
    }
}