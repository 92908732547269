#web{
    background-color:black;
    color:white;
    height:10vh;
    display:flex;
    align-items: center;
}

.devLink{
    text-decoration: none;
    font-family:"Quicksand Bold";
    font-size:130%;
    color:black;
}
.DevContainer{
    display:flex;
    justify-content: center;
    margin:7%;
    padding:5%;  
}

.DevContainer:hover, .devLink:hover{
    background-color:#03AC13;
    color:white;
}